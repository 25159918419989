import DefaultImageProfile from '../assets/images/no-profile-picture.svg';
import Ph2Logo from '../assets/images/ph2_logo.svg';
import Spinner from '../assets/images/spinner.gif';
import Rectangle from '../assets/images/Rectangle.png';
import Branding from '../assets/images/branding-light.svg';
import PhriendlyPhishing from '../assets/images/phriendly-phishing-logo.svg';
import Waves2 from '../assets/images/waves.png';
import ProfilePicture from '../assets/images/ProfilePicture.svg';
import ArrowDropDownWhite from '../assets/images/arrow_white_drop_down.svg';
import NotificationHeader from '../assets/images/NotificationWindow.svg';
import loadingPageIcon from '../assets/images/Spinner-1.2s-200px.gif';
import ProfilePicCard from '../assets/images/ProfilePicCard.svg';
import workSpaceIcon from '../assets/images/workspace-icon.svg';
import line1 from '../assets/images/Line1.svg';
import Ph2LogoDark from '../assets/images/Ph2Logo-dark.svg';
import NoAvatar from '../assets/images/no-avatar.png';
import Wave from '../assets/images/wave.png';
import NotFoundIcon from '../assets/images/NotFoundIcon.png';
import SearchIcon from '../assets/images/searchIcon.svg';
import ActiveBox from '../assets/images/acctive-box.svg';
import InActiveBox from '../assets/images/inactive-box.svg';

export const images = {
  DefaultImageProfile,
  Spinner,
  Rectangle,
  Branding,
  PhriendlyPhishing,
  Waves2,
  Ph2Logo,
  ProfilePicture,
  ArrowDropDownWhite,
  NotificationHeader,
  loadingPageIcon,
  ProfilePicCard,
  workSpaceIcon,
  line1,
  Ph2LogoDark,
  NoAvatar,
  Wave,
  NotFoundIcon,
  SearchIcon,
  ActiveBox,
  InActiveBox
};
